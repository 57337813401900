import './src/lib/sentry';

function onServiceWorkerUpdateReady() {
  window.location.reload();
}

export {
  // eslint-disable-next-line import/prefer-default-export
  onServiceWorkerUpdateReady,
};
