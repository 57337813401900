import * as Sentry from '@sentry/gatsby';

if (typeof window !== 'undefined' && window.Sentry === undefined) {
  Sentry.init({
    dsn: process.env.GATSBY_SENTRY_DSN,
    release: process.env.GATSBY_SENTRY_RELEASE,
    // debug: true,
  });
}

const { captureException, captureMessage } = Sentry;

export default captureException;
export {
  captureMessage,
};
